<template>
  <div
    class="flex flex-col gap-24 rounded-[3.2rem] py-32 px-[3rem] bg-surface-elevation-1 shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)]"
  >
    <div class="flex justify-between items-center">
      <s-text
        v-if="!isOpened && status"
        as="h3"
        role="title7"
        class="!font-bold text-brand-primary"
      >
        {{ status }}
      </s-text>
      <s-text
        as="h2"
        :role="titleSmall ? 'title7' : 'title5'"
        class="flex justify-start gap-4 !font-bold"
        :class="{ 'text-on-surface-elevation-2': titleSmall }"
      >
        {{ title }}
        <s-icon
          v-if="required"
          size="xs"
          icon="ic-v2-control-required-fill"
          class="text-tint-red-a400 self-start"
        />
      </s-text>
      <div class="shrink-0 flex items-center gap-16">
        <s-button v-if="hasReview" variant="outline" size="sm" @click="emits('review')">
          {{ $t('studio.group.collection.register_banner_img_preview_btn') }}
        </s-button>
        <button
          v-if="!noRefresh"
          type="button"
          class="flex items-center justify-center w-24 h-24"
          @click="emits('refresh')"
        >
          <s-icon
            size="3xl"
            icon="ic-v2-control-refresh-line"
            class="shrink-0 text-on-surface-elevation-1"
            srOnlyText="이 영역 새로고침"
          />
        </button>
        <span v-if="dateTime" class="ml-auto text-sm text-on-surface-elevation-3">
          {{ getDateTimeByLocale(dateTime) }}
        </span>
        <button
          type="button"
          class="flex items-center justify-center w-24 h-24"
          @click="toggleCollapse"
        >
          <s-icon
            size="3xl"
            :icon="isOpened ? 'ic-v2-control-arrow-up-line' : 'ic-v2-control-arrow-down-line'"
            class="shrink-0 text-on-surface-elevation-1"
            srOnlyText="이 영역을 열고 닫습니다"
          />
        </button>
      </div>
    </div>
    <div v-show="isOpened">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { getDateTimeByLocale } from '@/utils/date.util';

defineProps<{
  title?: string;
  required?: boolean;
  noRefresh?: boolean;
  hasReview?: boolean;
  status?: string;
  dateTime?: number;
  titleSmall?: boolean;
}>();

const emits = defineEmits<{
  review: [];
  refresh: [];
}>();

const isOpened = ref(true);

const toggleCollapse = () => {
  isOpened.value = !isOpened.value;
};

defineExpose({
  toggleCollapse
});
</script>
